// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("packs/smooth-scroll.js")
require("packs/slick.min.js")

$(window).on('load', function () {
  if (typeof inputed_operation_status === 'undefined') return;

  var input_operation_status = inputed_operation_status;//get data by javascript_tag
  var work_days_selected = work_days;
  if (input_operation_status === false) {
    $('input:radio[name=radio]').filter('[value=' + work_days_selected + ']').attr('checked', true);
    $('.modal001_update_info_popup').fadeIn();
    $(".btn-submit").on('click', function () {
      var volume_info = $('input[name="radio"]:checked').val();
      mydata = {
        profile: {
          work_days: volume_info
        }
      };
      var fd = new FormData();
      fd.append("jsondata", JSON.stringify(mydata));
      $.ajax({
        type: "POST",
        url: "/service-pw/update_info_volume",
        data: fd,
        cache: false,
        contentType: false,
        processData: false,
        dataType: "json"
      }).done(function (result) {
        if (volume_info == null) {
          return false;
        } else {
          $('.modal001_update_info_popup').fadeOut();
          return false;
        }
      }).fail(function () {
        return false;
      }).always(function () {
        return false;
      });
    });
  }
});